import {basePath, apiVersion} from "./config";

export default function signUpApi(data) {
    const url = `${basePath}/${apiVersion}/signup`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    }

    return fetch(url,params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        if(result.user){
            return {
                ok: true,
                message: "Usuario creado"
            };
        } return {
            ok: false,
            message: result.message
        };
    })
    .catch(err => {
        return {
            ok: false,
            message: err.message
        }
    });
}

export function signInApi(data) {
    const url = `${basePath}/${apiVersion}/signin`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    };

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function getUsersApi(token) {
    const url = `${basePath}/${apiVersion}/users`;

    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    };
    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function getUsersActiveApi(token, status) {
    const url = `${basePath}/${apiVersion}/users-active?active=${status}`;

    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    };
    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });

}

export function updateUserApi(token, user, userId) {
    const url = `${basePath}/${apiVersion}/update-user/${userId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token 
        },
        body: JSON.stringify(user)
    };

    return fetch(url,params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateUserApi(token, userId, status) {
    const url = `${basePath}/${apiVersion}/activate-user/${userId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        },
        body: JSON.stringify({
            active: status
        })
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result.message;
    })
    .catch(err => {
        return err.message;
    });
}

export function deleteUserApi(token, userId) {
    const url = `${basePath}/${apiVersion}/delete-user/${userId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result.message;
    })
    .catch(err => {
        return err.message;
    });
}

export function signUpAdminApi(token, data) {
    const url = `${basePath}/${apiVersion}/signup-admin`;
    
    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        },
        body: JSON.stringify(data)
    };

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result.message;
    })
    .catch(err => {
        return err.message;
    });
}